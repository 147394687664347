import "../assets/styles/misc.scss";

function NotFound() {
  return (
    <div className="notFound">
      <div className="h1">Not Found :(</div>
      <img
        alt="Gif of crying cat"
        id="sad_cat_gif"
        src="https://c.tenor.com/U2JqGqBXqZgAAAAM/cat-sad.gif"
      />
    </div>
  );
}

export default NotFound;
